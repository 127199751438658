/** css for info page starts here*/
.Info_appbar {
  background-color: #1A8EC2;
  display: flex;
}

.info_bar {
  flex: auto;
  display: flex;
}

p {
  font-size: 1rem;
  color: antiquewhite;

}
img{
  max-width: 100%;
  height: 50vh;
  border-radius: 10px;

}
.sliderImageDiv {
  display: flex;
  height: 100vh;
  color:#fff;
  background-color: #221f1f;
  align-items: center;
  justify-content: center;

  /* 100% of the viewport height */
}

.sliderImageDiv1{
  width: 50%;
  padding: 2rem;
}
.sliderImageDiv2{
  width: 50%;
}

.centeredText {
  display: flex;
  justify-content: center;
  align-items: center;

}

.typeWritterEffect {
  animation: fadeInOut 7s infinite alternate;
}
.headerTerxt {
  font-size: "2rem";
  text-align: center;
  color: #fff;
  background-color: #fff;
}
.service_component {
  padding: 20px;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  animation: slideUp 3s ease-in-out;
 
}
.singleCard {
  padding: 20px;
  color: #000;
  text-align: center;;

}
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.dropdown-content {
  font-size: 0.7rem;
  border-radius: 10px;
  display: none;
  position: absolute;
  background-color: #006635;
  top:200%;
  margin-top: -50px;
  padding-top: 50px;
  text-align: left;
  min-width: 16rem;
  z-index: -100;
}
.dropdown-content a {
  color: "#99ba02";
  padding: 3px 20px;
  text-decoration: none;
  align-items: center;
  display: block;
}
.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans';
	margin: 0;
}

.footer-distributed h3 span{
	color:  lightseagreen;
}

.dropdown:hover .dropdown-content {display: block;}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slideUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.cauroselHeader {
  text-align: center;
  font-size: 2.5rem;
  color:  "#99ba02";
}
.cauroselText {
  font-size: 1rem;
  color: "#99ba02";
  text-align: center;
}

@media (max-width: 992px) {

  /* … */
  .Info_appbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  p {
    font-size: 1rem;
  }

  .centeredText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: clamp(3rem, 2.8vw, 5rem);
    font-weight: bold;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .service_component {
    grid-template-columns: 1fr;
  
 }
 .sliderImageDiv {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  background-color: #000;
  color:#fff;
  align-items: center;
  justify-content: center;

  /* 100% of the viewport height */
}
.sliderImageDiv1{
  width: 100%;
  padding: 1rem;
}
.sliderImageDiv2{
  width: 100%;
}
.headerTerxt {
  margin-top: 3rem;
  font-size: "2rem";
  text-align: center;
  color: #000;
  background-color: #464646;
}
 
 
  .cauroselHeader {
    font-size: 1.5rem;
    padding-top: 10px;
    color:  "#99ba02";
  }
  .cauroselText {
    font-size: 0.8rem;
    color: "#99ba02";
  }
}